import React, { useState, useEffect } from "react";
import axios from "axios";
import About from "./About.js";
import Product from "./Product.js";
import {
  Card,
  Stack,
  Button,
  Typography,
  CircularProgress,
  Modal,
  ModalDialog,
  ModalClose,
  Input,
  Box,
} from "@mui/joy/";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
const { ip } = require("../../ip.js");

function HomeNPO(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const [data, setData] = useState();
  const [statusClick, setStatusClick] = useState(false);
  const [checkedStates, setCheckedStates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [modalOpen, setModalOpen] = useState([]); // Array to track modal open/close states
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;
  const confetti = props.confetti;
  const isAuthenticated = props.isAuthenticated;
  const [openAccept, setOpenAccept] = useState(false);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [products, setProducts] = useState(null);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [loading, setLoading] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [minPriceInput, setMinPriceInput] = useState("");
  const [maxPriceInput, setMaxPriceInput] = useState("");
  const [organization, setOrganization] = useState("");
  const isMobile = props.isMobile;
  const navigate = useNavigate();

  function find_checked_items(checked) {
    //loop through checkedStates and find all items that have been checked
    //return an array of all checked items
    let checkedItems = [];
    for (let i = 0; i < checked.length; i++) {
      for (let j = 0; j < checked[i].length; j++) {
        if (checked[i][j].id !== null) {
          checkedItems.push(checked[i][j]);
        }
      }
    }
    return checkedItems;
  }

  function Email(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_s2rydx7",
            "template_bs16y39",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization || "N/A",
              content: content,
              status: status,
              ask_id: ask_id,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  function createContent(products) {
    let content = "";
    for (let i = 0; i < products.length; i++) {
      if (products[i].id !== null) {
        content += products[i].product_name;
        content += ": ";
        content += products[i].quantity;
        if (i !== products.length - 1) {
          content += ",\n";
        }
      }
    }
    return content;
  }

  function validate_accept(group) {
    //loop through group and check if any are null, 
    //also check if requested quantity is less than or equal to remaining quantity or less than  or equal to0
    for (let i = 0; i < group.length; i++) {
      if (group[i].id !== null) {
        if ((group[i].requested_quantity > group[i].remaining_quantity) || (group[i].requested_quantity <= 0)) {
          return false;
        } 
      }
    }
    return true;
  }

  function show_accept(index) {
    if (!checkedStates[index]) return false;
    for (let i = 0; i < checkedStates[index].length; i++) {
      if (checkedStates[index][i] && checkedStates[index][i].id !== null && checkedStates[index][i]?.requested_quantity > 0 && checkedStates[index][i]?.requested_quantity <= checkedStates[index][i]?.remaining_quantity) {
        return true;
      }
    }
    return false;
  }

  function changeMultipleStatus(id, group, new_status) {
    if (!validate_accept(group)) {
      Error_Message("Please select valid quantities");
      return;
    }
    axios
      .put(`${ip}/asks/changeMultipleStatus/`, {
        id: id,
        group: group,
        new_status: new_status,
      })
      .then((response) => {
        if (response.data) {
          setStatusClick(!statusClick);
        }
      })
      .then(() => {
        if (new_status === 1) {
          Success_Message("Ask Accepted!");
          confetti.addConfetti();
          setSearchQuery("");
        }
      });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ip}/asks/allAsks`);
        setData(response.data);
        const initialCheckedStates = response.data.map((group) =>
          Array(group.products.length).fill({
            id: null,
            product_name: null,
            requested_quantity: null,
            remaining_quantity: null,
          })
        );
        setCheckedStates(initialCheckedStates);
        setModalOpen(Array(response.data.length).fill(false));

        const products = await axios.get(`${ip}/products/`);
        setProducts(products.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [statusClick]);

  useEffect(() => {
    if (!data) return;

    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    const filtered = data.filter(
      (group) =>
        (group?.ask[0].addr &&
          group?.ask[0].addr.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group?.ask[0].schoolAddr &&
          group?.ask[0].schoolAddr
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].created &&
          group.ask[0].created.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].organization &&
          group.ask[0].organization
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].school &&
          group.ask[0].school.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].healthhub &&
          group.ask[0].healthhub
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        group.products.some((product) =>
          product.product_name.toLowerCase().includes(lowerCaseSearchQuery)
        )
    );

    setFilteredData(filtered);
  }, [searchQuery, data]);

  useEffect(() => {
    setEstimatedPrice(0);
    for (const ask of checkedStates) {
      for (const item of ask) {
        if (item.id !== null) {
          const product = products.find(
            (product) => product.productName === item.product_name
          );
          if (item?.requested_quantity && item?.remaining_quantity && item?.requested_quantity <= item?.remaining_quantity)
          {
            setEstimatedPrice((prevPrice) => {
              return (
                prevPrice +
                product.price *
                  (item.requested_quantity ? item.requested_quantity : 0)
              );
            });

          }
        }
      }
    }
  }, [checkedStates, products]);

  const handleCheckBoxChange = (
    e,
    askIndex,
    productIndex,
    item,
  ) => {
    if (e.target.type === "number") {
      const newQuantity = parseInt(e.target.value);
      setCheckedStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[askIndex][productIndex] = {
          id: item.ask_id,
          product_name: item.product_name,
          remaining_quantity: item.remaining_quantity,
          requested_quantity: newQuantity,
        };
        return newStates;
      });
      return;
    }
    else {
      setCheckedStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[askIndex][productIndex] = e.target.checked
          ? { id: item.ask_id, product_name: item.product_name, remaining_quantity: item.remaining_quantity}
          : { id: null, product_name: null, remaining_quantity: null, requested_quantity: null };
        return newStates;
      });
    }
  };

  //not complete, still need to write backend route
  const find_org = (checked) => {
    //loop through checked and all data in each index of checked
    //find the first instance of data
    //run query to find org based on id found
    //return org name
    for (let i = 0; i < checked.length; i++) {
      for (let j = 0; j < checked[i].length; j++) {
        if (checked[i][j].id !== null) {
          axios.get(`${ip}/asks/org/${checked[i][j].id}`).then((response) => {
            setOrganization(response.data[0]?.organization);
            return response.data[0]?.organization;
          });
        }
      }
    }
    return null;
  }

  const show_num_checked = (index) => {
    let count = 0;
    for (let i = 0; i < checkedStates[index].length; i++) {
      if (checkedStates[index][i] && checkedStates[index][i].id !== null) {
        count++;
      }
    }
    return count;
  };

  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          margin: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const show_loading = () => {
    setLoading(true);
  };

  const hide_loading = () => {
    setLoading(false);
  };

  function hide_accept_all_button(askIndex) {
    //check if any item has been checked
    for (let i = 0; i < checkedStates[askIndex].length; i++) {
      if (checkedStates[askIndex][i]?.id) {
        return true;
      }
    }
    return false;
  }

  return (
    <Box sx={{ px: { xs: 2, md: 6 }, pb: 12 }}>
      <Stack spacing={1}>
        {loading && <CircularProgress />}
        <ToastContainer />
        {!isAuthenticated ? (
          <Typography color="danger" level="h4">
            Please Login to accept asks
          </Typography>
        ) : null}
        <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography level="h1">All Asks</Typography>
        </Stack>
        <Stack direction={isMobile ? "column" : "row"} spacing={2}>
          <Typography level="body-lg">Search By:</Typography>
          <Input
            size="md"
            placeholder="Item, Date, Address, School, Organization"
            id="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ width: isMobile ? "100%" : 300 }}
          />
          <Typography level="body-lg">Filter By Price:</Typography>
          <Input
            size="md"
            placeholder="Min Price"
            startDecorator="$"
            value={minPriceInput}
            slotProps={{
              input: {
                min: 1,
                step: 1,
              },
            }}
            onChange={(e) => {
              if (e.target.value === "") {
                setMinPrice("");
              }
              setMinPrice(e.target.value);
              setMinPriceInput(e.target.value);
            }}
            sx={{ width: isMobile ? "50%" : 150 }}
            type="number"
          />
          <Input
            size="md"
            placeholder="Max Price"
            startDecorator="$"
            value={maxPriceInput}
            onChange={(e) => {
              if (e.target.value === "") {
                setMaxPrice("");
              }
              setMaxPrice(e.target.value);
              setMaxPriceInput(e.target.value);
            }}
            sx={{ width: isMobile ? "50%" : 150 }}
            slotProps={{
              input: {
                max: 10000,
                step: 1,
              },
            }}
            type="number"
          />
          <Button
            color="primary"
            sx={{ width: isMobile ? "50%" : 150 }}
            onClick={() => {
              if (!minPrice && maxPrice) {
                setMinPrice(0);
              }
              if (minPrice && !maxPrice) {
                setMaxPrice(10000);
              }
              if (minPrice === 0 && maxPrice === 10000) {
                return;
              }
              const filtered = data.filter((group) => {
                let total = 0;
                for (const product of group.products) {
                  const productData = products.find(
                    (p) => p.productName === product.product_name
                  );
                  total += productData.price * product.remaining_quantity;
                }
                return total >= minPrice && total <= maxPrice;
              });
              show_loading();
              setShowReset(true);
              setTimeout(() => {
                setFilteredData(filtered);
                hide_loading();
                Success_Message("Filter Applied!");
              }, 1000);
            }}
          >
            Filter
          </Button>
          {showReset ? (
            <Button
              color="primary"
              onClick={() => {
                setMinPrice(0);
                setMaxPrice(10000);
                setFilteredData(data);
                setShowReset(false);
                setMinPriceInput("");
                setMaxPriceInput("");
                Success_Message("Filter Reset!");
              }}
              sx={{ width: isMobile ? "50%" : 150 }}
            >
              Reset
            </Button>
          ) : null}
        </Stack>
        {filteredData.length === 0 ? (
          <Card>
            <Typography level="body-lg">
              No Asks, check back later or refine search
            </Typography>
          </Card>
        ) : (
          filteredData.map((group, index) => (
            <Stack spacing={1} key={index}>
              <Card
                color="neutral"
                invertedColors={false}
                orientation="vertical"
                size="md"
                variant="outlined"
              >
                <About
                  data={group.ask}
                  user={user}
                  isAuthenticated={isAuthenticated}
                />
                <Product
                  data={group.products}
                  user={user}
                  {...group.ask}
                  askIndex={index}
                  Success_Message={Success_Message}
                  Error_Message={Error_Message}
                  isAuthenticated={isAuthenticated}
                  confetti={confetti}
                  checked={checkedStates[index]} // Use the specific checked state for the ask
                  setCheckedStates={setCheckedStates}
                  handleCheckBoxChange={handleCheckBoxChange}
                  isMobile={isMobile}
                />
                <Stack spacing={1} direction={"row"}>
                  {isAuthenticated &&
                  group &&
                  group.products &&
                  group.products.length > 1 &&
                  !hide_accept_all_button(index) ? (
                    <>
                      <Button
                        color="success"
                        onClick={() => {
                          setModalOpen((prevState) => {
                            const newState = [...prevState];
                            newState[index] = true;
                            return newState;
                          });
                        }}
                        sx={{ margin: 1, width: "auto" }}
                      >
                        Accept All
                      </Button>
                      <Modal
                        open={modalOpen[index]}
                        onClose={() =>
                          setModalOpen((prevState) => {
                            const newState = [...prevState];
                            newState[index] = false;
                            return newState;
                          })
                        }
                      >
                        <ModalDialog>
                          <ModalClose />
                          <Typography alignContent={"center"} level="body-lg">
                            Are you sure you want to accept all?
                          </Typography>
                          <Stack
                            spacing={1}
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <Button
                              color="success"
                              sx={{ width: "auto" }}
                              onClick={() => {
                                changeMultipleStatus(
                                  user.id,
                                  group.products,
                                  1
                                );
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              color="danger"
                              sx={{ width: "auto" }}
                              onClick={() =>
                                setModalOpen((prevState) => {
                                  const newState = [...prevState];
                                  newState[index] = false;
                                  return newState;
                                })
                              }
                            >
                              No
                            </Button>
                          </Stack>
                        </ModalDialog>
                      </Modal>
                    </>
                  ) : null}
                  {isAuthenticated && show_accept(index) ? (
                    <>
                      <Button
                        color="success"
                        onClick={() => {
                          setOpenAccept(true);
                        }}
                        sx={{ margin: 1, width: "auto", alignSelf: "right" }}
                      >
                        Accept Selected
                      </Button>
                      <Modal
                        open={openAccept}
                        onClose={() => {
                          setOpenAccept(!openAccept);
                        }}
                      >
                        <ModalDialog>
                          <ModalClose />
                          <Typography alignContent={"left"} level="body-md">
                            Accept asks from {"\n"} id: {group.ask[0].id}?
                          </Typography>
                          <Stack
                            spacing={1}
                            direction={"row"}
                            justifyContent={"center"}
                          >
                            <Button
                              color="success"
                              sx={{ width: "auto" }}
                              onClick={() => {
                                setOpenAccept(false);
                                changeMultipleStatus(
                                  user.id,
                                  checkedStates[index],
                                  1
                                );
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              color="danger"
                              sx={{ width: "auto" }}
                              onClick={() => setOpenAccept(false)}
                            >
                              No
                            </Button>
                          </Stack>
                        </ModalDialog>
                      </Modal>
                    </>
                  ) : null}
                </Stack>
              </Card>
            </Stack>
          ))
        )}
        {estimatedPrice !== 0 && (
          <Box
            sx={{
              mt: 2,
              p: 3,
              textDecorationColor: "#FFFFFF",
              backgroundColor: "#2f6bcb",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              textAlign: "left",
              transform:
                estimatedPrice !== 0 ? "translateY(0)" : "translateY(100%)",
              opacity: estimatedPrice !== 0 ? 1 : 0,
              transition: "transform 0.5s ease-out, opacity 0.5s ease-out",
            }}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography level={isMobile ? 'body-lg' : 'h4'} sx={{ color: "#FFFFFF" }}>
                <b>Estimated Total: ${estimatedPrice}</b>
              </Typography>
              <Typography level="h4" sx={{ color: "#FFFFFF" }}>
                <Button
                  variant="solid"
                  color="success"
                  size={isMobile ? "sm" : "lg"}
                  onClick={() => {
                    navigate("/donate", {
                      state: {
                        donation_amount: estimatedPrice,
                        organization: organization,
                        checkedItems: find_checked_items(checkedStates),
                      },
                    });
                  }}
                >
                  Donate ${estimatedPrice}?
                </Button>
              </Typography>
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
}

export default HomeNPO;
