import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import "@fontsource/inter";
import Divider from "@mui/material/Divider";
import {
Input,
Table,
Stack,
Card,
CircularProgress,
Typography,
Button,
Modal,
ModalDialog,
ModalClose,
Box,
Link,
Select,
Option,
Autocomplete,
Avatar,
Skeleton,
} from "@mui/joy/";
import About from "./About";
import Product from "./Product";
import { ip } from "../../ip";
import emailjs from "@emailjs/browser";
import { NumericFormat } from "react-number-format";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { Tooltip } from "react-tooltip";

function HomeSchool(props) {
axios.defaults.withCredentials = true;
const admin = props.admin ? true : false;
const user = props.user;
const isMobile = props.isMobile;
const navigate = useNavigate();
let location = useLocation();
const [data, setData] = useState([]);
const [tempData, setTempData] = useState([]);
//const [checkedStates, setCheckedStates] = useState({});
const checkedStates = props.checkedStates;
const setCheckedStates = props.setCheckedStates;
const handleCheckBoxChange = props.handleCheckBoxChange;
const [openModal, setOpenModal] = useState(null);
const [openModal2, setOpenModal2] = useState(null);
const [openComplete, setOpenComplete] = useState(null);
const [open, setOpen] = useState(null);
const [loading, setLoading] = useState(true);
const [searchQuery, setSearchQuery] = useState("");
const [filteredData, setFilteredData] = useState([]);
const [products, setProducts] = useState([]);
const [openAddItem, setOpenAddItem] = useState(null);
const [newItems, setNewItems] = useState([]);
const [error, setError] = useState(false);
const [newQuantity, setNewQuantity] = useState([]);
const [newLink, setNewLink] = useState([]);
const [newProduct, setNewProduct] = useState([]);
const [productNames, setProductNames] = useState([]);
const Success_Message = props.Success_Message;
const Error_Message = props.Error_Message;
const isAuthenticated = props.isAuthenticated;
const confetti = props.confetti;
const clean_data = props.clean_data;
const changeMultipleStatus = props.changeMultipleStatus;
const addToInventory = props.addToInventory;
const statusClick = props.statusClick;
const setStatusClick = props.setStatusClick;
let show_delete = false;
let show_received = false;
let show_complete = false;

useEffect(() => {
    axios.get(`${ip}/asks/asks/${user.id}`).then((response) => {
    if (response.data) {
        setData(response.data);
        setLoading(false);
        const initialCheckedStates = response.data.map((group) =>
          Array(group.products.length).fill({
              id: null,
              product_name: null,
              quantity: null,
              acceptance_data: null,
          })
        );
        setCheckedStates(initialCheckedStates);
    }
    });
    axios.get(`${ip}/products`).then((response) => {
    setProducts(response.data);
    });
}, [statusClick]);

useEffect(() => {
    if (!data) return;

    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    // Filter data based on search query
    // change filter to include date, school, address, and product name
    const filtered = data.filter(
    (group) =>
        (group.ask[0].addr &&
        group.ask[0].addr.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].schoolAddr &&
        group.ask[0].schoolAddr
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].created &&
        group.ask[0].created.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].organization &&
        group.ask[0].organization
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        (group.ask[0].school &&
        group.ask[0].school.toLowerCase().includes(lowerCaseSearchQuery)) ||
        (group.ask[0].healthhub &&
        group.ask[0].healthhub
            .toLowerCase()
            .includes(lowerCaseSearchQuery)) ||
        group.products.some((product) =>
        product.product_name.toLowerCase().includes(lowerCaseSearchQuery))
    );

    setFilteredData(filtered);
}, [searchQuery, data]);

useEffect(() => {
    setTempData(JSON.parse(JSON.stringify(data)));
}, [data]);

const addTextboxSet = (manual) => {
    if (manual) {
    setNewItems([
        ...newItems,
        { Item: "__manual__", Quantity: "", Link: "" },
    ]);
    } else {
    setNewItems([...newItems, { Item: "", Quantity: "", Link: "" }]);
    }
};

const handleTextboxChange = (index, textboxName, value) => {
    const newData = [...newItems];
    newData[index][textboxName] = value;
    setNewItems(newData);
};

const handleTempDataChange = (index, textboxName, value, product_index) => {
    setTempData((prev) => {
    const newData = [...prev];
    newData[index]["products"][product_index][textboxName] = value;
    return newData;
    });
};

function show_button(data) {
    let num_status_2 = 0;
    for (const [key, value] of Object.entries(data)) {
    if (value.accepted === 2) {
        num_status_2 += 1;
    }
    }
    if (data.length > 1 && num_status_2 === data.length) {
    return true;
    }
    return false;
}

function createContent(data) {
    let content = "";
    for (const [key, value] of Object.entries(data)) {
    if (typeof value === "object" && value !== null) {
        content += `${value.product_name}: ${value.quantity}\n`;
    }
    }
    return content;
}

//need to figure out how to do this properly because products in one ask could be sent from different npo's
//I think this is done, need to test case where 2 npo's accept asks from same school
function EmailNPO(data) {
    let unique_npo = {};
    for (const [key, value] of Object.entries(data)) {
    if (typeof value === "object" && value !== null) {
        if (value.accepted === 2) {
        if (unique_npo[value.accepted_id] === undefined) {
            unique_npo[value.accepted_id] = [];
            unique_npo[value.accepted_id].push({
            id: value.id,
            product_name: value.product_name,
            quantity: value.quantity,
            });
        } else {
            unique_npo[value.accepted_id].push({
            id: value.id,
            product_name: value.product_name,
            quantity: value.quantity,
            });
        }
        }
    }
    }
    for (const [key, value] of Object.entries(unique_npo)) {
    axios.get(`${ip}/email/${key}`).then((response) => {
        if (response.data) {
        emailjs
            .send(
            "service_s2rydx7",
            "template_bs16y39",
            {
                to_email: response.data.email,
                from_email: user.email,
                from_name: user.fullName,
                from_organization: user.organization,
                content: createContent(value),
                status: "Completed",
                ask_id: value[0].id,
            },
            "x7LM43rlkJsqov2r7"
            )
            .then((response) => {
            if (response.status === 200) {
                console.log("email sent");
            } else {
                console.log("email not sent");
            }
            });
        }
    });
    }
}

//styling components rendered after ask is created
function showAskCreated() {
    if (location.state) {
        if (location.state.ask_created) {
            toast.success("Ask Created!", {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: "ask_created",
            onClose: () => {
                location.state.ask_created = false;
            },
            });
        }
        if (location.state.confetti) {
            props.confetti.addConfetti().then(() => {
            location.state.confetti = false;
            });
        }
    }
}

function retrieve_images() {
    //loop through tempdata.products
    //loop through products
    //if products has image_url, set image_url of tempdata to products.image_url
    for (let i = 0; i < tempData.length; i++) {
    for (let j = 0; j < tempData[i].products.length; j++) {
        Object.keys(products).map((productId) => {
        if (
            products[productId].productName ===
            tempData[i].products[j].product_name
        ) {
            setTempData((prev) => {
            const newData = [...prev];
            newData[i].products[j].image_url = products[productId].image_url;
            return newData;
            });
        }
        });
    }
    }
}

function changeStatus(id, product_name, new_status) {
    axios
    .put(`${ip}/asks/changeStatus/`, {
        id: id,
        product_name: product_name,
        new_status: new_status,
        user_id: user.id,
    })
    .then((response) => {
        if (response.data) {
        setStatusClick(!statusClick);
        if (new_status === 3) {
            Success_Message("Completed! Adding to Inventory...");
        }
        }
    });
}

// function addToInventory(product_name, quantity, organization, ask_id) {
//   axios
//     .post(`${ip}/inventory/received`, {
//       product_name: product_name,
//       quantity: quantity,
//       date_received: new Date(),
//       organization: organization,
//       ask_id: ask_id,
//     })
//     .then((response) => {
//       if (response.data) {
//         Success_Message("Added to Inventory!");
//         setStatusClick(!statusClick);
//       }
//     });
// }

// function clean_data(data, status) {
//   let clean_data = data;
//   for (const [key, value] of Object.entries(data)) {
//     if (!value.id && (value.status !== null || value.status !== 0)) {
//       clean_data[key] = {
//         id: null,
//         product_name: null,
//         quantity: null,
//         accepted: null,
//       };
//     }
//   }
//   return clean_data;
// }

const show_num_checked = (index) => {
    let count = 0;
    for (let i = 0; i < checkedStates[index].length; i++) {
    if (checkedStates[index][i] && checkedStates[index][i].id !== null) {
        count++;
    }
    }
    return count;
};

// function changeMultipleStatus(id, group, new_status, old_status) {
//   axios
//     .put(`${ip}/asks/changeMultipleStatus/`, {
//       id: id,
//       group: clean_data(group.products ? group.products : group, old_status),
//       new_status: new_status,
//     })
//     .then((response) => {
//       if (response.data) {
//         setStatusClick(!statusClick);
//       }
//     })
//     .then(() => {
//       Success_Message("Completed! Adding to Inventory...");
//       //loop through group and add each product to inventory
//       for (const [key, value] of Object.entries(
//         group.products ? group.products : group
//       )) {
//         if (typeof value === "object" && value !== null) {
//           addToInventory(
//             value.product_name,
//             value.quantity,
//             user.organization,
//             value.id
//           );
//         }
//       }
//     });
// }

function deleteAskProducts(id, group) {
    let clean = clean_data(group, 0);
    axios
    .delete(`${ip}/asks/deleteMultipleAskProduct/`, {
        data: {
        ask_id: id,
        group: clean,
        },
    })
    .then((response) => {
        if (response.data) {
        setStatusClick(!statusClick);
        Error_Message("Deleted!");
        }
    });
}

function show_delete_or_received(index, ask_id) {
    //loop through checkedStates[index] and check if all ids are null
    if (checkedStates[index] === undefined) {
    return null;
    }
    let all_null = true;
    for (const [key, value] of Object.entries(checkedStates[index])) {
    if (value?.id !== null) {
        all_null = false;
    }
    }
    if (all_null) {
    return null;
    }
    let show_delete = false;
    let show_received = false;
    let show_complete = false;
    for (const [key, value] of Object.entries(checkedStates[index])) {
    if (typeof value === "object" && value?.id !== null) {
        if (!value.acceptance_data || !value.acceptance_data[0]?.accepted_id) {
        show_delete = true;
        show_complete = true;
        } else if (value.status === "2") {
        show_received = true;
        }
    }
    }
    if (show_delete && show_received && show_complete) {
    return (
        <Stack spacing={1} direction={"row"}>
        <Button
            color="danger"
            onClick={() => {
            setOpenModal2(index);
            }}
            sx={{ margin: 1, width: 100 }}
        >
            Delete
        </Button>
        <Modal
            open={openModal2 === index}
            onClose={() => {
            setOpenModal2(null);
            }}
        >
            <ModalDialog>
            <ModalClose />
            <Typography
                component={"span"}
                alignContent={"center"}
                level="body-lg"
            >
                Are you sure you want to delete an item from ask id: {ask_id}?
            </Typography>
            <Stack spacing={1} direction={"row"} justifyContent={"center"}>
                <Button
                color="success"
                onClick={() => {
                    //delete this and replace with delete askProduct
                    deleteAskProducts(ask_id, checkedStates[index]);
                    setOpenModal2(null);
                }}
                >
                Yes
                </Button>
                <Button
                color="danger"
                onClick={() => {
                    setOpenModal2(null);
                }}
                >
                No
                </Button>
            </Stack>
            </ModalDialog>
        </Modal>
        <Button
            color="success"
            onClick={() => {
            setOpenModal(index);
            }}
        >
            Mark Received
        </Button>
        <Modal
            open={openModal === index}
            onClose={() => {
            setOpenModal(null);
            }}
        >
            <ModalDialog>
            <ModalClose />
            <Typography
                component={"span"}
                alignContent={"center"}
                level="body-lg"
            >
                Are you sure you want to mark as received? This will be added to
                your inventory.
            </Typography>
            <Stack spacing={1} direction={"row"} justifyContent={"center"}>
                <Button
                color="success"
                onClick={() => {
                    changeMultipleStatus(user.id, checkedStates[index], 3, 2);
                    setOpenModal(false);
                }}
                >
                Yes
                </Button>
                <Button
                color="danger"
                onClick={() => {
                    setOpenModal(null);
                }}
                >
                No
                </Button>
            </Stack>
            </ModalDialog>
        </Modal>
        <Button
            color="success"
            sx={{ alignContent: "right" }}
            onClick={() => {
            setOpenComplete(index);
            }}
        >
            Complete
        </Button>
        <Modal
            open={openComplete === index}
            onClose={() => {
            setOpenComplete(null);
            }}
        >
            <ModalDialog>
            <ModalClose />
            <Typography
                component={"span"}
                alignContent={"center"}
                level="body-lg"
            >
                Are you sure you want to complete this ask? Only use this if
                items have been anonymously donated.
            </Typography>
            <Stack spacing={1} direction={"row"} justifyContent={"center"}>
                <Button
                color="success"
                onClick={() => {
                    setOpenComplete(null);
                    changeMultipleStatus(user.id, checkedStates[index], 3, 0);
                }}
                >
                Yes
                </Button>
                <Button
                color="danger"
                onClick={() => {
                    setOpenComplete(null);
                }}
                >
                No
                </Button>
            </Stack>
            </ModalDialog>
        </Modal>
        </Stack>
    );
    }
    if (show_delete && show_complete) {
    return (
        <>
        <Stack direction={"row"} spacing={1}>
            <Button
            color="danger"
            onClick={() => {
                setOpenModal(index);
            }}
            sx={{ margin: 1, width: 100 }}
            >
            Delete
            </Button>
            <Modal
            open={openModal === index}
            onClose={() => {
                setOpenModal(null);
            }}
            >
            <ModalDialog>
                <ModalClose />
                <Typography
                component={"span"}
                alignContent={"center"}
                level="body-lg"
                >
                Are you sure you want to delete an item from ask id: {ask_id}?
                </Typography>
                <Stack spacing={1} direction={"row"} justifyContent={"center"}>
                <Button
                    color="success"
                    onClick={() => {
                    //need to delete this
                    deleteAskProducts(ask_id, checkedStates[index]);
                    setOpenModal(false);
                    }}
                >
                    Yes
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                    setOpenModal(null);
                    }}
                >
                    No
                </Button>
                </Stack>
            </ModalDialog>
            </Modal>
            <Button
            color="success"
            sx={{ alignContent: "right" }}
            onClick={() => {
                setOpenComplete(index);
            }}
            >
            Complete
            </Button>
            <Modal
            open={openComplete === index}
            onClose={() => {
                setOpenComplete(null);
            }}
            >
            <ModalDialog>
                <ModalClose />
                <Typography
                component={"span"}
                alignContent={"center"}
                level="body-lg"
                >
                Are you sure you want to complete this item for ask id:{" "}
                {ask_id}? Only use this if item(s) have been anonymously
                donated.
                </Typography>
                <Stack spacing={1} direction={"row"} justifyContent={"center"}>
                <Button
                    color="success"
                    onClick={() => {
                    changeMultipleStatus(user.id, checkedStates[index], 3, 0);
                    setOpenComplete(null);
                    }}
                >
                    Yes
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                    setOpenComplete(null);
                    }}
                >
                    No
                </Button>
                </Stack>
            </ModalDialog>
            </Modal>
        </Stack>
        </>
    );
    }
    if (show_received) {
    return (
        <>
        <Stack spacing={1} direction={"row"}>
            <Button
            color="success"
            onClick={() => {
                setOpenModal(index);
            }}
            >
            Mark {show_num_checked(index)} Received
            </Button>
            <Modal
            open={openModal === index}
            onClose={() => {
                setOpenModal(null);
            }}
            >
            <ModalDialog>
                <ModalClose />
                <Typography
                component={"span"}
                alignContent={"center"}
                level="body-lg"
                >
                Are you sure you want to mark as received? This will be added
                to your inventory.
                </Typography>
                <Stack spacing={1} direction={"row"} justifyContent={"center"}>
                <Button
                    sx={{ margin: 1, width: 100 }}
                    color="success"
                    onClick={() => {
                    changeMultipleStatus(user.id, checkedStates[index], 3, 2);
                    setOpenModal(null);
                    }}
                >
                    Yes
                </Button>
                <Button
                    color="danger"
                    onClick={() => {
                    setOpenModal(null);
                    }}
                >
                    No
                </Button>
                </Stack>
            </ModalDialog>
            </Modal>
        </Stack>
        </>
    );
    }
}

function show_survey(group) {
    let show = false;
    for (const [key, value] of Object.entries(group)) {
    if (typeof value === "object" && value !== null) {
        if (value.accepted === 3) {
        show = true;
        }
    }
    }
    return show;
}

function none_accepted(products) {
    let show = true;
    for (const [key, value] of Object.entries(products)) {
    if (typeof value === "object" && value !== null) {
        if (value.acceptance_data && value.acceptance_data[0]?.accepted_id) {
        show = false;
        }
    }
    }
    return show;
}

// const handleCheckBoxChange = (
//   e,
//   askIndex,
//   productIndex,
//   item,
//   quantity,
//   status
// ) => {
//   setCheckedStates((prevStates) => {
//     const newStates = [...prevStates];
//     newStates[askIndex][productIndex] = e.target.checked
//       ? { id: item.ask_id, product_name: item.product_name, quantity, status, acceptance_data: item.acceptance_data }
//       : { id: null, product_name: null, quantity: null, acceptance_data: null};
//     return newStates;
//   });
// };

if (loading || !data) {
    return (
    <Box
        sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        margin: "auto",
        }}
    >
        <CircularProgress />
    </Box>
    );
}

function addToAsk(id) {
    setOpenAddItem(id);
    //loop through newItems to make sure that everything is filled out
    for (const [key, value] of Object.entries(newItems)) {
    if (
        value.Item === "" ||
        value.Quantity === "" ||
        (value.ManualItem && value.ManualItem === "")
    ) {
        Error_Message("Please fill out all fields");
        return;
    }
    }
    //loop through newItems and add each item to the ask
    for (const [key, value] of Object.entries(newItems)) {
    axios
        .post(`${ip}/asks/addToAsk`, {
        ask_id: id,
        product_name:
            value.Item === "__manual__" ? value.ManualItem : value.Item,
        quantity: value.Quantity,
        link: value.Link,
        user_id: user.id,
        })
        .then((response) => {
        if (response.data.message === "Product already exists in ask") {
            Error_Message(`${value.Item} already exists in ask`);
        } else if (response.data.message === "Ask does not exist") {
            Error_Message("Ask does not exist");
        } else if (response.data.message === "success") {
            setStatusClick(!statusClick);
            if (value.Item !== "__manual__") {
            Success_Message(`${value.Item} Added!`);
            } else {
            Success_Message(`${value.ManualItem} Added!`);
            }
        }
        });
    }
}

const validateEdit = (newQuantity, newLink, productNames) => {
    let error = "";
    if (newQuantity === "0") {
    setError("quantity");
    //after setting error, set it back to false
    setTimeout(() => {
        setError("");
    }, 5000);
    error = "quantity";
    }
    //check if link is valid, link can be empty
    if (
    newLink &&
    !newLink.includes("http://") &&
    !newLink.includes("https://") &&
    !newLink.includes("www.")
    ) {
    setError("link");
    //after setting error, set it back to false
    setTimeout(() => {
        setError("");
    }, 5000);
    error = "link";
    }
    return error;
};

const edit = (index, data, tempData) => {
    //use tempData to edit
    //loop through tempData and check if quantity is 0 or link is invalid
    //validate each quantity and link
    for (let i = 0; i < tempData[index].products.length; i++) {
    if (
        validateEdit(
        tempData[index].products[i].quantity,
        tempData[index].products[i].link,
        tempData[index].products[i].product_name
        ) === "quantity"
    ) {
        Error_Message("Cannot change quantity to 0!");
        setTempData(JSON.parse(JSON.stringify(data)));
        return;
    }
    if (
        validateEdit(
        tempData[index].products[i].quantity,
        tempData[index].products[i].link,
        tempData[index].products[i].product_name
        ) === "link"
    ) {
        Error_Message("Invalid link!");
        setTempData(JSON.parse(JSON.stringify(data)));
        return;
    }
    }
    //if temp data equals data, do nothing
    if (
    JSON.stringify(data[index].products) ===
    JSON.stringify(tempData[index].products)
    ) {
    return;
    }
    axios
    .post(`${ip}/asks/edit`, {
        data: data[index].products,
        tempData: tempData[index].products,
    })
    .then((response) => {
        if (response.data.message === "success") {
        setStatusClick(!statusClick);
        Success_Message("Edited!");
        }
    });
};

return (
    <Box sx={{ px: { xs: 2, md: 6 } }}>
    {data.length === 0 && !admin ? (
        <Stack spacing={1} minHeight={700}>
        <Card>
            <Typography component={"span"} level="h1">
            No Asks Yet!
            </Typography>
            <Stack spacing={1} direction={"row"}>
            <Button
                color="success"
                onClick={() => {
                navigate("/create");
                }}
                sx={{ margin: 1, width: "auto" }}
            >
                Create Ask
            </Button>
            </Stack>
        </Card>
        </Stack>
    ) : (
        <>
        <ToastContainer />
        {showAskCreated()}
        <Stack spacing={2} direction="row" justifyContent="space-between">
            {admin ? null : (
            <Typography component={"span"} level="h1">
                My Asks
            </Typography>
            )}
        </Stack>
        <br></br>
        {!admin ? (
            <>
            <Stack direction={"row"} spacing={2}>
                <Typography component={"span"} level="body-lg">
                Search By:
                </Typography>
                <Input
                size="md"
                placeholder="Item, Date, Address, School, Organization"
                id="search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ width: 400 }}
                />
            </Stack>
            <br></br>
            </>
        ) : null}
        <Stack spacing={1}>
            {filteredData.length === 0 ? (
            <Card>
                <Typography component={"span"} level="body-lg">
                No Results Found
                </Typography>
            </Card>
            ) : null}
            {filteredData.map((group, index) => (
            <Stack spacing={1} key={index}>
                <Card
                color="neutral"
                invertedColors={false}
                orientation="vertical"
                size="md"
                variant="outlined"
                >
                <About
                    data={group.ask}
                    user={user}
                    isAuthenticated={isAuthenticated}
                    admin={admin ? true : false}
                    handleCheckBoxChange={handleCheckBoxChange}
                />
                <Product
                    address={group.ask[0].addr}
                    data={group.products}
                    user={user}
                    changeStatus={changeStatus}
                    addToInventory={addToInventory}
                    statusClick={statusClick}
                    setStatusClick={setStatusClick}
                    Success_Message={Success_Message}
                    Error_Message={Error_Message}
                    askIndex={index}
                    handleCheckBoxChange={handleCheckBoxChange}
                    checked={checkedStates[index]}
                    isAuthenticated={isAuthenticated}
                    newQuantity={newQuantity}
                    setNewQuantity={setNewQuantity}
                    newLink={newLink}
                    setNewLink={setNewLink}
                    productNames={productNames}
                    setProductNames={setProductNames}
                    newProduct={newProduct}
                    setNewProduct={setNewProduct}
                    isMobile={isMobile}
                />
                <Stack spacing={1} direction={"row"}>
                    {show_button(group.products) ? (
                    <>
                        <Button
                        color="success"
                        onClick={() => {
                            setOpen(index);
                        }}
                        sx={{ margin: 1, width: 100 }}
                        >
                        Mark All Received
                        </Button>
                        <Modal
                        open={open === index}
                        onClick={() => {
                            setOpen(null);
                        }}
                        >
                        <ModalDialog>
                            <Typography
                            component={"span"}
                            alignContent={"center"}
                            level="body-lg"
                            >
                            Are you sure you want to mark items from id:{" "}
                            {group.products[0].id}? These will be added to
                            your inventory.
                            </Typography>
                            <Stack
                            spacing={1}
                            direction={"row"}
                            justifyContent={"center"}
                            >
                            <Button
                                color="success"
                                onClick={() => {
                                setOpen(null);
                                changeMultipleStatus(user.id, group, 3, 2);
                                EmailNPO(group);
                                }}
                            >
                                Yes
                            </Button>
                            <Button
                                color="danger"
                                onClick={() => setOpen(null)}
                            >
                                No
                            </Button>
                            </Stack>
                        </ModalDialog>
                        </Modal>
                    </>
                    ) : null}
                    <Stack spacing={1} direction={"column"}>
                    {show_delete_or_received(index, group.ask[0].id)}
                    {show_survey(group.products) ? (
                        <>
                        <b>
                            <Typography component={"span"}>
                            Please Fill Out This Survey For{" "}
                            <Typography component={"span"} color="success">
                                <b>Completed</b>
                            </Typography>{" "}
                            Products:{" "}
                            <Link
                                href="https://dpscd.az1.qualtrics.com/jfe/form/SV_7Vu8Bp57tXa8iNf"
                                target="_blank"
                            >
                                here
                            </Link>
                            </Typography>
                        </b>
                        </>
                    ) : null}
                    </Stack>
                    {none_accepted(group.products) &&
                    !show_delete &&
                    !show_complete &&
                    !show_received ? (
                    <>
                        <Button
                        sx={{ maxWidth: 100 }}
                        onClick={() => {
                            setOpenAddItem(index);
                            retrieve_images();
                        }}
                        >
                        Edit Ask
                        </Button>
                        <Modal
                        open={openAddItem !== null && openAddItem === index}
                        onClose={() => {
                            setOpenAddItem(null);
                            setNewItems([]);
                            setTempData(JSON.parse(JSON.stringify(data)));
                        }}
                        >
                        <ModalDialog sx={{ width: "80%", maxWidth: 1000, overflow: 'scroll' }}>
                            <Typography component={"span"} level="h1">
                            Edit Ask
                            </Typography>
                            <ModalClose />
                            <Table
                            aria-label="basic table"
                            variant="outlined"
                            borderAxis="xBetween"
                            >
                            <thead>
                                <tr>
                                <th>
                                    <Typography
                                    component={"span"}
                                    level="body-md"
                                    >
                                    Item
                                    </Typography>
                                </th>
                                <th>
                                    <Typography
                                    component={"span"}
                                    level="body-md"
                                    >
                                    {isMobile ? "Qty" : "Quantity"}
                                    </Typography>
                                </th>
                                <th>
                                    <Typography
                                    component={"span"}
                                    level="body-md"
                                    >
                                    Link
                                    </Typography>
                                </th>
                                <th>
                                    <Typography
                                    component={"span"}
                                    level="body-md"
                                    >
                                    Delete
                                    </Typography>
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tempData[index]?.products?.map(
                                (set, product_index) => (
                                    <tr
                                    className="textboxes"
                                    key={product_index}
                                    >
                                        <td>
                                            <Stack
                                            direction={"row"}
                                            spacing={1}
                                            alignItems={"center"}
                                            >
                                            <Autocomplete
                                                options={Object.keys(products).map(
                                                (productId) =>
                                                    products[productId].productName
                                                )}
                                                onChange={(e, value) => {
                                                handleTempDataChange(
                                                    index,
                                                    "product_name",
                                                    value,
                                                    product_index
                                                );
                                                Object.keys(products).map(
                                                    (productId) => {
                                                    if (
                                                        products[productId]
                                                        .productName === value
                                                    ) {
                                                        handleTempDataChange(
                                                        index,
                                                        "image_url",
                                                        products[productId]
                                                            .image_url,
                                                        product_index
                                                        );
                                                    }
                                                    }
                                                );
                                                }}
                                                renderInput={(params) => (
                                                <input {...params} />
                                                )}
                                                size={isMobile ? "sm" : "md"}
                                                sx={{ width: 175 }}
                                                placeholder="Select an item"
                                                value={set.product_name}
                                            />
                                            {tempData[index]?.products[
                                                product_index
                                            ]?.image_url ? (
                                                <Avatar
                                                src={
                                                    tempData[index]?.products[
                                                    product_index
                                                    ]?.image_url
                                                }
                                                variant="plain"
                                                />
                                            ) : null}
                                            </Stack>
                                        </td>
                                        <td>
                                            <Input
                                            variant="outlined"
                                            placeholder="Quantity"
                                            value={
                                                set.quantity ? set.quantity : ""
                                            }
                                            onChange={(e) =>
                                                handleTempDataChange(
                                                index,
                                                "quantity",
                                                e.target.value,
                                                product_index
                                                )
                                            }
                                            size={isMobile ? "sm" : "md"}
                                            onKeyDown={(e) => {
                                                if (
                                                !(
                                                    (e.key >= "0" &&
                                                    e.key <= "9") ||
                                                    e.key === "Backspace" ||
                                                    e.key === "Delete"
                                                )
                                                ) {
                                                e.preventDefault();
                                                }
                                            }}
                                            slotProps={{
                                                input: {
                                                component: NumericFormat,
                                                },
                                            }}
                                            sx={{
                                                width: "100%",
                                            }}
                                            error={
                                                error === "quantity" ? true : false
                                            }
                                            />
                                        </td>
                                        <td>
                                            <Input
                                            type="link"
                                            className="form-control"
                                            id="link"
                                            name="link"
                                            placeholder="Enter a link to the product"
                                            sx={{
                                                maxWidth: 500,
                                                width: "100%",
                                            }}
                                            value={set.link ? set.link : ""}
                                            onChange={(e) =>
                                                handleTempDataChange(
                                                index,
                                                "link",
                                                e.target.value,
                                                product_index
                                                )
                                            }
                                            size={isMobile ? "sm" : "md"}
                                            error={
                                                error === "link" ? true : false
                                            }
                                            />
                                        </td>
                                    </tr>
                                )
                                )}
                                {newItems.map((set, index) => (
                                <tr className="textboxes" key={index}>
                                    <td>
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        alignItems={"center"}
                                    >
                                        {newItems[index].Item !==
                                        "__manual__" ? (
                                        <>
                                            <Autocomplete
                                            options={Object.keys(
                                                products
                                            ).map(
                                                (productId) =>
                                                products[productId]
                                                    .productName
                                            )}
                                            onChange={(e, value) => {
                                                handleTextboxChange(
                                                index,
                                                "Item",
                                                value
                                                );
                                                Object.keys(products).map(
                                                (productId) => {
                                                    if (
                                                    products[productId]
                                                        .productName === value
                                                    ) {
                                                    handleTextboxChange(
                                                        index,
                                                        "image_url",
                                                        products[productId]
                                                        .image_url
                                                    );
                                                    }
                                                }
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <input {...params} />
                                            )}
                                            size="md"
                                            sx={{ width: 250 }}
                                            placeholder="Select an item"
                                            />
                                            {newItems[index]?.image_url ? (
                                            <Avatar
                                                src={newItems[index]?.image_url}
                                                variant="plain"
                                            />
                                            ) : null}
                                        </>
                                        ) : (
                                        <>
                                            <br></br>
                                            <Input
                                            variant="outlined"
                                            placeholder="Enter New Product"
                                            value={
                                                newItems[index].ManualItem
                                                ? newItems[index].ManualItem
                                                : ""
                                            }
                                            onChange={(e) =>
                                                handleTextboxChange(
                                                index,
                                                "ManualItem",
                                                e.target.value
                                                )
                                            }
                                            size="md"
                                            sx={{
                                                minWidth: 150,
                                            }}
                                            />
                                        </>
                                        )}
                                        <br></br>
                                        {newItems[index].Item !==
                                        "__manual__" ? (
                                        <>
                                            <AddIcon
                                            onClick={() => {
                                                //change current index of new item to manual
                                                const newData = [...newItems];
                                                newData[index].Item =
                                                "__manual__";
                                                setNewItems(newData);
                                            }}
                                            onHover={() => {}}
                                            color="primary"
                                            size="lg"
                                            style={{ cursor: "pointer" }}
                                            data-tooltip-id="addIcon"
                                            data-tooltip-content="Add a new product not in dropdown"
                                            data-tooltip-place="bottom"
                                            />
                                            <Tooltip
                                            id="addIcon"
                                            style={{ zIndex: "auto" }}
                                            size={isMobile ? "sm" : "md"}
                                            />
                                        </>
                                        ) : (
                                        <>
                                            <CancelIcon
                                            onClick={() => {
                                                const newData = [...newItems];
                                                newData[index].Item = "";
                                                setNewItems(newData);
                                            }}
                                            color="danger"
                                            size={isMobile ? "sm" : "md"}
                                            style={{ cursor: "pointer" }}
                                            data-tooltip-id="cancelIcon"
                                            data-tooltip-content="Cancel adding a new product"
                                            data-tooltip-place="top"
                                            />
                                            <Tooltip id="cancelIcon" />
                                        </>
                                        )}
                                    </Stack>
                                    </td>
                                    <td>
                                    <Input
                                        variant="outlined"
                                        placeholder="Quantity"
                                        value={
                                        newItems[index].Quantity
                                            ? newItems[index].Quantity
                                            : set.textbox2
                                        }
                                        onChange={(e) =>
                                        handleTextboxChange(
                                            index,
                                            "Quantity",
                                            e.target.value
                                        )
                                        }
                                        onKeyDown={(e) => {
                                        if (
                                            !(
                                            (e.key >= "0" && e.key <= "9") ||
                                            e.key === "Backspace" ||
                                            e.key === "Delete"
                                            )
                                        ) {
                                            e.preventDefault();
                                        }
                                        }}
                                        sx={{
                                        width: "100%",
                                        }}
                                        error={
                                        error === "quantity" ? true : false
                                        }
                                        size={isMobile ? "sm" : "md"}
                                    />
                                    </td>
                                    <td>
                                    <Input
                                        type="link"
                                        className="form-control"
                                        id="link"
                                        name="link"
                                        placeholder="Enter a link to the product"
                                        sx={{ maxWidth: 500, width: "100%" }}
                                        value={
                                        newItems[index].Link
                                            ? newItems[index].Link
                                            : set.Link
                                        }
                                        size={isMobile ? "sm" : "md"}
                                        onChange={(e) =>
                                        handleTextboxChange(
                                            index,
                                            "Link",
                                            e.target.value
                                        )
                                        }
                                    />
                                    </td>
                                    <td>
                                    <Button
                                        onMouseOver={() => {}}
                                        color="danger"
                                        onClick={() => {
                                        const newData = [...newItems];
                                        newData.splice(index, 1);
                                        setNewItems(newData);
                                        }}
                                        size={isMobile ? "sm" : "md"}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </Table>
                            <Stack spacing={2} maxWidth={isMobile ? 150 : 200}>
                                <Typography component={"span"} level="body-lg">
                                    <b>
                                    Total:{" "}
                                    {newItems.length + group.products.length}{" "}
                                    item(s)
                                    </b>
                                </Typography>
                                <Button
                                    onClick={() => {
                                    addTextboxSet(false);
                                    }}
                                    color="primary"
                                    size={isMobile ? "sm" : "md"}
                                >
                                    Add New Item
                                </Button>
                                <Button
                                    onClick={() => setNewItems([])}
                                    color="danger"
                                    sx={{ width: 'auto' }}
                                    size={isMobile ? "sm" : "md"}
                                >
                                    Clear New Items
                                </Button>
                                </Stack>
                            <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            >
                            <Button
                                color="success"
                                onClick={() => {
                                addToAsk(group.ask[0].id);
                                setNewItems([]);
                                setTimeout(() => {
                                    edit(index, data, tempData);
                                }, 1000);
                                }}
                                sx={{ width: 100 }}
                                size='lg'
                            >
                                Done!
                            </Button>
                            </Stack>
                        </ModalDialog>
                        </Modal>
                    </>
                    ) : null}
                </Stack>
                <br></br>
                </Card>
            </Stack>
            ))}
        </Stack>
        </>
    )}
    <br></br>
    {admin ? (
        //if admin make a thick divider
        <Divider sx={{ borderBottomWidth: 5 }} />
    ) : null}
    </Box>
);
}

export default HomeSchool;
