import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Input,
  Button,
  Card,
  Stack,
  Table,
  Sheet,
  Typography,
  Box,
  Divider,
  Modal,
  ModalDialog,
  Autocomplete,
  FormControl,
  FormLabel,
  Avatar,
} from "@mui/joy";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { ip } from "../../ip";

function CreateAsk(props) {
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  const [products, setProducts] = useState({});
  const [error, setError] = useState("");
  const [quantityErrors, setQuantityErrors] = useState([]);
  const [itemErrors, setItemErrors] = useState([]);
  const [schoolAddr, setSchoolAddr] = useState("");
  const [addr, setAddr] = useState("");
  const [allLocations, setAllLocations] = useState([""]);
  const [healthHubs, setHealthHubs] = useState([]);
  const [healthHub, setHealthHub] = useState("");
  const [school, setSchool] = useState("");
  const [schools, setSchools] = useState([]);
  const user = props.user;
  const [data, setData] = useState([{ Item: "", Quantity: "", Link: "", ManualItem: "", affiliate: false }]);
  const [open, setOpen] = useState(false);
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;
  const isMobile = props.isMobile;

  console.log('products', products);

  console.log('data', data);

  const addTextboxSet = () => {
    setData([...data, { Item: "", Quantity: "", Link: "", ManualItem: "", image_url: "", affiliate: false }]);
  };

  const handleTextboxChange = (index, textboxName, value) => {
    const newQuantityErrors = [...quantityErrors];
    if (textboxName === "Quantity") {
      if (value === "" || isNaN(value) || value <= 0) {
        newQuantityErrors[index] = true;
      } else {
        newQuantityErrors[index] = false;
      }
    }
    setQuantityErrors(newQuantityErrors);

    const newItemErrors = [...itemErrors];
    if (
      (textboxName === "Item" || textboxName === "ManualItem") &&
      value === ""
    ) {
      newItemErrors[index] = true;
    } else {
      newItemErrors[index] = false;
    }
    setItemErrors(newItemErrors);

    const newData = [...data];
    newData[index][textboxName] = value;
    setData(newData);
  };

  useEffect(() => {
    axios.get(`${ip}/products`).then((response) => {
      setProducts(response.data);
    });
    axios.get(`${ip}/locations`).then((response) => {
      setAllLocations(response.data);
      setSchools(response.data);
    });
    setHealthHubs([
      { name: "Western", address: "1500 Scotten St, Detroit, MI 48209" },
      { name: "Central", address: "2425 Tuxedo Street Detroit, MI 48206" },
      { name: "Denby", address: "12800 Kelly Road Detroit, MI 48224" },
      { name: "Southeastern", address: "3030 Fairview Street Detroit, MI 48214" },
      { name: "EEVPA", address: "5020 Cadieux Road Detroit, MI 48224" },
      { name: "Osborn", address: "11600 East 7 Mile Road Detroit, MI 48205" },
      { name: "Marygrove", address: "8425 West McNichols Detroit, MI 48221" },
      { name: "Mumford", address: "17525 Wyoming Street Detroit, MI 48221" },
      { name: "MLK", address: "3200 East Lafayette Street Detroit, MI 48207" },
    ]);
  }, []);

  useEffect(() => {
    if (!healthHub) {
      const uniqueSchools = allLocations.filter(
        (school, index, self) =>
          index === self.findIndex((s) => s.name === school.name)
      );
      setSchools(uniqueSchools);
    } else {
      const filteredSchools = allLocations.filter(
        (location) => location.healthhub === healthHub
      );
      setSchools(filteredSchools);
    }
  }, [healthHub, allLocations]);

  useEffect(() => {
    if (addr !== "") {
      setError("");
    }
  }, [addr]);

  useEffect(() => {
    setQuantityErrors((prevQuantityErrors) => {
      const newQuantityErrors = [...prevQuantityErrors];
      while (newQuantityErrors.length < data.length) {
        newQuantityErrors.push(false);
      }
      return newQuantityErrors;
    });

    setItemErrors((prevItemErrors) => {
      const newItemErrors = [...prevItemErrors];
      while (newItemErrors.length < data.length) {
        newItemErrors.push(false);
      }
      return newItemErrors;
    });
  }, [data]);

  function validateData(data) {
    if (data.length === 0) {
      return "no data";
    }
    for (let i = 0; i < data.length; i++) {
      const set = data[i];
      if (!set.Item && !set.Quantity) {
        return "no data";
      } else if (!set.Item || !set.Quantity) {
        if (!set.Quantity) {
          return "q";
        }
        if (!set.Item) {
          return "i";
        }
      } else if (set.Item === "" && set.Quantity === "") {
        return "no data";
      } else if (set.Item === "__manual__") {
        if (set.ManualItem === "") {
          return "m";
        }
        if (!set.ManualItem) {
          itemErrors[i] = true;
          return "i";
        }

        let found = false;
        Object.keys(products).map((productId) => {
          if (products[productId].productName === set.ManualItem) {
            return found = true;
          }
          return found = false;
        });
        if (found) {
          return "same product";
        }
      }
      if (set.Quantity <= 0) {
        return "0";
      }
    }
    return true;
  }

  const validate_ask = () => {
    if (addr === "") {
      Error_Message("Address Missing!");
      setError("location");
    } else if (validateData(data) === "i") {
      Error_Message("Item Missing!");
      setError("item");
    } else if (validateData(data) === "q") {
      Error_Message("Quantity Missing!");
      setError("quantity");
    } else if (validateData(data) === "0") {
      Error_Message("Quantity Must Be Greater Than 0!");
      setError("quantity");
    } else if (validateData(data) === "no data") {
      Error_Message("Item and Quantity Missing!");
      setError("item");
    } else if (validateData(data) === "m") {
      Error_Message("Item Missing!");
      setError("manual");
    } else if (validateData(data) === "same product") {
      Error_Message("Product Already Exists!");
      setError("manual");
    } else {
      setOpen(true);
    }
  };

  const checkDuplicateProduct = () => {
    axios.get(`${ip}/inventory/checkDuplicateProduct`, {
      product_name: data.ManualItem ? data.ManualItem : data.Item,
      location: addr,
    });
  };

  const create = () => {
    axios
      .post(`${ip}/asks/createAsk`, {
        id: 0,
        creator: user.fullName,
        created: new Date().toJSON(),
        addr: addr,
        schoolAddr: schoolAddr,
        healthHub: healthHub,
        school: school,
        organization: user.organization,
        user_id: user.id,
        email: user.email,
        data: data,
      })
      .then((response) => {
        Success_Message("Ask Created!");
      })
      .then(() => {
        setData([]);
        setOpen(false);
        navigate("/home", {
          state: { ask_created: true, ask_id: 0, confetti: true },
        });
      });
  };

  return (
    <Box padding={3} minHeight={750}>
      <Card sx={{ maxWidth: 1000, mx: "auto", p: 4 }}>
        <Stack spacing={2}>
          <Typography level="h1">Create Ask</Typography>
          <ToastContainer />
          <Typography level="body-lg" align="left">
            Input the Products and Quantities Needed
          </Typography>
          <Divider />
        </Stack>
        <Typography level="body-lg" align="left">
          <b>Common Locations:</b>
        </Typography>
        <Stack spacing={3} direction={"column"} alignItems={"center"}>
          <Stack
            spacing={3}
            direction={isMobile ? "column" : "row"} // Switch to column layout for mobile
            justifyContent={"space-evenly"}
            alignItems={"center"}
            sx={{ width: "100%" }}
          >
            {/* First Column */}
            <Stack
              spacing={3}
              direction={"column"}
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              <FormControl>
                <FormLabel>Health Hub</FormLabel>
                <Autocomplete
                  options={healthHubs.map((hub) => hub.name)}
                  onChange={(e, value) => {
                    setHealthHub(value);
                    setAddr(
                      healthHubs?.find((hub) => hub?.name === value)?.address ||
                        ""
                    );
                  }}
                  sx={{ width: "100%" }}
                  placeholder="Select a health hub"
                />
              </FormControl>
              <FormControl>
                <FormLabel>School</FormLabel>
                <Autocomplete
                  options={schools.map((school) => school.name)}
                  onChange={(e, value) => {
                    setSchool(value);
                    const selectedSchool = schools.find(
                      (school) => school.name === value
                    );
                    if (selectedSchool) {
                      setSchoolAddr(selectedSchool.address);
                    }
                    if (value === null) {
                      setSchoolAddr("");
                    }
                  }}
                  sx={{ width: "100%" }}
                  placeholder="Select a school"
                />
              </FormControl>
            </Stack>

            {/* Second Column */}
            <Stack
              spacing={3}
              direction={"column"}
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              <FormControl>
                <FormLabel>
                  Delivery Address (ex: 717 N 2ND ST, MANKATO, MN 56001)
                </FormLabel>
                <Input
                  type="location"
                  className="form-control"
                  id="delivery-location"
                  name="delivery-location"
                  placeholder="Enter the address you want products to be sent to"
                  value={addr}
                  onChange={(e) => setAddr(e.target.value)}
                  sx={{ width: "100%" }}
                  error={error === "location"}
                />
              </FormControl>

              <FormControl>
                <FormLabel>School Address</FormLabel>
                <Input
                  type="location"
                  className="form-control"
                  id="location"
                  name="location"
                  value={schoolAddr}
                  onChange={(e) => setSchoolAddr(e.target.value)}
                  sx={{ width: "100%" }}
                  error={error === "location"}
                  placeholder="Enter the address of the school"
                />
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
        <br />
        <Stack spacing={2}>
          <Sheet
            sx={{
              width: "100%", // Ensure the sheet takes full width of the screen
              maxWidth: isMobile ? "100%" : 750, // Adjust max width for mobile
              overflowX: "auto", // Enable horizontal scrolling for small screens
              alignItems: "flex-end",
            }}
          >
            <Table
              aria-label="basic table"
              variant="outlined"
              borderAxis="xBetween"
              sx={{
                width: "100%", // Ensure the table takes full width of its container
                tableLayout: "auto", // Fixed table layout for better control over column widths
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      width: isMobile ? "15%" : "25%",
                      padding: "8px 16px",
                    }}
                  >
                    <Typography level={isMobile ? "body-xs" : "body-lg"}>
                      Item
                    </Typography>
                  </th>
                  <th
                    style={{
                      width: isMobile ? "25%" : "15%",
                      padding: "8px 16px",
                    }}
                  >
                    <Typography level={isMobile ? "body-xs" : "body-lg"}>
                      {isMobile ? "Qty" : "Quantity"}
                    </Typography>
                  </th>
                  <th style={{ width: "30%", padding: "8px 16px" }}>
                    <Typography level={isMobile ? "body-xs" : "body-lg"}>
                      Product Link (Optional)
                    </Typography>
                  </th>
                  <th style={{ width: "25%", padding: "8px 16px" }}>
                    <Typography level={isMobile ? "body-xs" : "body-lg"}>
                      Remove
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((set, index) => (
                  <tr className="textboxes" key={index}>
                    <td style={{ padding: "8px 16px" }}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {data[index].Item !== "__manual__" ? (
                          <>
                            <Autocomplete
                              options={Object.keys(products).map(
                                (productId) => products[productId].productName
                              )}
                              onChange={(e, value) => {
                                handleTextboxChange(index, "Item", value);
                                Object.keys(products).map((productId) => {
                                  if (
                                    products[productId].productName === value
                                  ) {
                                    // Set the image url if it exists
                                    handleTextboxChange(
                                      index,
                                      "image_url",
                                      products[productId].image_url
                                    );
                                    // Set the link if affilaite link exists
                                    if (products[productId].affiliate_link != null) {
                                      handleTextboxChange(
                                        index,
                                        "Link",
                                        products[productId].affiliate_link
                                      );
                                      handleTextboxChange(index, "affiliate", true);
                                    }
                                  }
                                  return null;
                                });
                                
                              }}
                              size={isMobile ? "sm" : "md"}
                              sx={{ maxWidth: isMobile ? 150 : 175 }} // Adjust width for mobile
                              placeholder="Select an item"
                              error={itemErrors[index]}
                              disabled={data[index].Item === "__manual__"}
                            />
                            {data[index]?.image_url && (
                              <Avatar
                                src={data[index]?.image_url}
                                variant="plain"
                                size={isMobile ? "sm" : "md"}
                                sx={{
                                  width: isMobile ? 24 : 40,
                                  height: isMobile ? 24 : 40,
                                }} // Adjust avatar size for mobile
                              />
                            )}
                          </>
                        ) : (
                          <Input
                            variant="outlined"
                            placeholder="Enter New Product"
                            value={data[index].ManualItem || ""}
                            onChange={(e) =>
                              handleTextboxChange(
                                index,
                                "ManualItem",
                                e.target.value
                              )
                            }
                            size={isMobile ? "sm" : "md"} // Adjust input size for mobile
                            sx={{
                              minWidth: isMobile ? 120 : 150, // Adjust input width for mobile
                            }}
                            error={itemErrors[index]}
                          />
                        )}
                        {data[index].Item !== "__manual__" ? (
                          <>
                            <AddIcon
                              onClick={() => {
                                const newData = [...data];
                                newData[index].Item = "__manual__";
                                setData(newData);
                              }}
                              color="primary"
                              size={isMobile ? "sm" : "lg"}
                              style={{ cursor: "pointer" }}
                              data-tooltip-id="addIcon"
                              data-tooltip-content="Add a new product not in dropdown"
                              data-tooltip-place="top"
                            />
                            <Tooltip id="addIcon" />
                          </>
                        ) : (
                          <>
                            <CancelIcon
                              onClick={() => {
                                const newData = [...data];
                                newData[index].Item = "";
                                newData[index].ManualItem = "";
                                setData(newData);
                              }}
                              color="danger"
                              size={isMobile ? "sm" : "lg"}
                              style={{ cursor: "pointer" }}
                              data-tooltip-id="cancelIcon"
                              data-tooltip-content="Cancel adding a new product"
                              data-tooltip-place="top"
                            />
                            <Tooltip id="cancelIcon" />
                          </>
                        )}
                      </Stack>
                    </td>
                    <td style={{ padding: "8px 16px" }}>
                      <Input
                        variant="outlined"
                        placeholder="Quantity"
                        size={isMobile ? "sm" : "md"} // Adjust input size for mobile
                        value={data[index].Quantity || set.textbox2}
                        onChange={(e) =>
                          handleTextboxChange(index, "Quantity", e.target.value)
                        }
                        onKeyDown={(e) => {
                          if (
                            !(
                              (e.key >= "0" && e.key <= "9") ||
                              e.key === "Backspace" ||
                              e.key === "Delete"
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                        sx={{ maxWidth: 80 }} // Adjust width of Quantity input
                        error={quantityErrors[index]}
                      />
                    </td>
                    <td style={{ padding: "8px 16px" }}>
                      <Input
                        type="link"
                        placeholder="Enter a link to the product"
                        sx={{ maxWidth: isMobile ? 250 : 500 }} // Adjust width for mobile
                        value={data[index].Link || set.Link}
                        onChange={(e) =>
                          handleTextboxChange(index, "Link", e.target.value)
                        }
                        size={isMobile ? "sm" : "md"} // Adjust input size for mobile
                        disabled={data[index].affiliate}
                      />
                    </td>
                    <td style={{ padding: "8px 16px" }}>
                      <Button
                        color="danger"
                        size={isMobile ? "sm" : "md"} // Adjust button size for mobile
                        onClick={() => {
                          const newData = [...data];
                          newData.splice(index, 1);
                          setData(newData);

                          const newQuantityErrors = [...quantityErrors];
                          newQuantityErrors.splice(index, 1);
                          setQuantityErrors(newQuantityErrors);
                          const newItemErrors = [...itemErrors];
                          newItemErrors.splice(index, 1);
                          setItemErrors(newItemErrors);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <br />
          </Sheet>
        </Stack>
        <Stack spacing={2} direction="row" maxWidth={400}>
          <Button
            onClick={addTextboxSet}
            color="primary"
            size={isMobile ? "sm" : "lg"} // Adjust button size for mobile
          >
            Add Item
          </Button>
          <Button
            onClick={() => setData([])}
            color="danger"
            size={isMobile ? "sm" : "lg"}
          >
            Clear All Items
          </Button>
        </Stack>
        <Typography level="h4" sx={{ mt: 2 }}>
          <b>Total: {data.length} item(s)</b>
        </Typography>
        <br />
        <Stack sx={{ justifyContent: "right" }}>
          <Button
            size="lg"
            onClick={validate_ask}
            color="success"
            sx={{ maxWidth: 200, alignSelf: "end" }}
          >
            Done!
          </Button>
        </Stack>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog>
            <Typography alignContent={"center"} level="body-lg">
              Are you sure you want to create this ask?
            </Typography>
            <Stack spacing={1} direction={"row"} justifyContent={"center"}>
              <Button color="success" onClick={create}>
                Yes
              </Button>
              <Button color="danger" onClick={() => setOpen(false)}>
                No
              </Button>
            </Stack>
          </ModalDialog>
        </Modal>
      </Card>
    </Box>
  );
}

export default CreateAsk;
