import React from "react";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Card,
  Stack,
  Input,
  Button,
  Modal,
  ModalDialog,
  Table,
  CircularProgress,
  Autocomplete,
} from "@mui/joy";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ip } from "../../ip";

function Inventory(props) {
  axios.defaults.withCredentials = true;
  const user = props.user;
  const [received, setReceived] = useState(null);
  const [filteredReceived, setFilteredReceived] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [stockedInventory, setStockedInventory] = useState(null);
  const [filteredStockedInventory, setFilteredStockedInventory] =
    useState(null);
  const [searchValueStocked, setSearchValueStocked] = useState("");
  const [newQuantities, setNewQuantities] = useState([]);
  const [newBins, setNewBins] = useState([]);
  const [statusClick, setStatusClick] = useState(false);
  const [openStock, setOpenStock] = useState(false);
  const [bins, setBins] = useState([]);
  const Success_Message = props.Success_Message;
  const Error_Message = props.Error_Message;

  useEffect(() => {
    axios
      .get(`${ip}/inventory/received/${user.organization}`)
      .then((response) => {
        if (response.data) {
          setReceived(response.data);
          setFilteredReceived(response.data);
        }
      });
    axios
      .get(`${ip}/inventory/stocked/${user.organization}`)
      .then((response) => {
        if (response.data) {
          setStockedInventory(response.data);
          setFilteredStockedInventory(response.data);
          setNewQuantities(response.data.map((product) => product.quantity));
          setNewBins(response.data.map((product) => product.bin));
        }
      });
  }, [statusClick, user.organization]);

  useEffect(() => {
    if (!received) {
      return;
    }
    const query = searchValue.toLowerCase();
    const filter = received.filter(
      (product) =>
        product.productName.toLowerCase().includes(searchValue) ||
        product.location_address.toLowerCase().includes(searchValue) ||
        product.supplier.toLowerCase().includes(searchValue) ||
        product.healthhub.toLowerCase().includes(searchValue)
    );
    setFilteredReceived(filter);
  }, [searchValue, received]);

  useEffect(() => {
    if (!stockedInventory) {
      return;
    }
    const query = searchValueStocked.toLowerCase();
    const filter = stockedInventory.filter(
      (product) =>
        product.productName.toLowerCase().includes(searchValueStocked) ||
        product.location_address.toLowerCase().includes(searchValueStocked) ||
        product.supplier.toLowerCase().includes(searchValueStocked) ||
        product.healthhub.toLowerCase().includes(searchValueStocked)
    );
    setFilteredStockedInventory(filter);
  }, [searchValueStocked, stockedInventory]);

  const validate_bin = (index) => {
    if (bins.length <= index) {
      Error_Message("Field Missing!");
      return false;
    }
    if (bins[index].length <= 0) {
      Error_Message("Field Missing!");
      return false;
    }

    return true;
  };

  const addBin = (index) => {
    if (validate_bin(index)) {
      axios
        .post(`${ip}/inventory/addBin`, {
          bin: bins[index],
          id: received[index].id,
          stocked_by: user.id,
          stocked_on: new Date(),
        })
        .then((response) => {
          if (response.data) {
            setStatusClick(!statusClick);
            Success_Message("Item Stocked!");
            setBins(() => {
              const newBins = [...bins];
              newBins[index] = "";
              return newBins;
            });
          }
        });
    }
  };

  const handleInputChange = useCallback((e, index) => {
    const newValue = e.target.value;
    setBins((prevBins) => {
      const newBins = [...prevBins];
      newBins[index] = newValue;
      return newBins;
    });
  });

  if (!received || !stockedInventory) {
    // Data is not available yet
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          margin: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box paddingBottom={0} borderBottom={0} minHeight={750} paddingLeft={5}>
      <ToastContainer />
      <Stack spacing={2} minHeight={300}>
        <Stack spacing={2} minHeight={300}>
          <Typography level="h1" textAlign={"left"} justifyContent={"center"}>
            Received Inventory
          </Typography>
          <Stack spacing={2} direction={"row"}>
            <Typography level="body-lg" textAlign={"left"}>
              Filter By:
            </Typography>
            <Input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              sx={{ minWidth: 300 }}
              placeholder="Search by Product Name, Address, or Supplier"
            />
          </Stack>
          <Card>
            {filteredReceived && filteredReceived.length ? (
              <Table>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Health Hub</th>
                    <th>Location</th>
                    <th>Date Received</th>
                    <th>Supplier</th>
                    <th>Bin</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReceived &&
                    filteredReceived.map((product, index) => (
                      <tr key={index}>
                        <td>{product.productName}</td>
                        <td>{product.quantity}</td>
                        <td>{product.healthhub}</td>
                        <td>{product.location_address}</td>
                        <td>{product.date_received.split("T")[0]}</td>
                        <td>{product.supplier}</td>
                        <td>
                          <Stack spacing={1} direction={"row"}>
                            <Input
                              value={bins[index]}
                              sx={{ maxWidth: 50 }}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                            <Button
                              onClick={() => {
                                addBin(index);
                              }}
                              color="success"
                            >
                              Stock
                            </Button>
                            <Modal
                              open={openStock}
                              onClose={() => {
                                setOpenStock(false);
                              }}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <ModalDialog>
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                  Are you sure you want to stock this item?
                                </Typography>
                                <Button
                                  color="success"
                                  onClick={() => {
                                  }}
                                >
                                  Yes
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    setOpenStock(false);
                                  }}
                                >
                                  No
                                </Button>
                              </ModalDialog>
                            </Modal>
                          </Stack>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <Typography level="body-sm" textAlign={"center"}>
                No items have been received yet.
              </Typography>
            )}
          </Card>
        </Stack>
        <Stack spacing={2} minHeight={700}>
          <Typography level="h1" textAlign={"left"} justifyContent={"center"}>
            Stocked Inventory
          </Typography>
          <Stack spacing={2} direction={"row"}>
            <Typography level="body-lg" textAlign={"left"}>
              Filter By:
            </Typography>
            <Input
              value={searchValueStocked}
              onChange={(e) => {
                setSearchValueStocked(e.target.value);
              }}
              sx={{ minWidth: 300 }}
              placeholder="Search by Product Name, Address, or Supplier"
            />
          </Stack>
          <Card>
            {filteredStockedInventory && filteredStockedInventory.length ? (
              <Table>
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Health Hub</th>
                    <th>Location</th>
                    <th>Date Received</th>
                    <th>Date Stocked</th>
                    <th>Stocked By</th>
                    <th>Supplier</th>
                    <th>Bin</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStockedInventory &&
                    filteredStockedInventory.map((product, index) => (
                      <tr key={index}>
                        <td>{product.productName}</td>
                        <td>
                          <Input
                            value={newQuantities[index]}
                            sx={{ maxWidth: 50, fontSize: 14 }}
                            onChange={(e) => {
                              setNewQuantities((prevQuantities) => {
                                const newQuantities = [...prevQuantities];
                                newQuantities[index] = e.target.value;
                                return newQuantities;
                              });
                            }}
                            placeholder={product.quantity.toString()}
                          />
                        </td>
                        <td>{product.healthhub}</td>
                        <td>{product.location_address}</td>
                        <td>{product.date_received.split("T")[0]}</td>
                        <td>{product.stocked_on.split("T")[0]}</td>
                        <td>{product.stocked_by_name}</td>
                        <td>{product.supplier}</td>
                        <td>
                          <Input
                            value={newBins[index]}
                            placeholder={product.bin}
                            sx={{ maxWidth: 50, fontSize: 14 }}
                            onChange={(e) => {
                              setNewBins((prevInventory) => {
                                const newInventory = [...prevInventory];
                                newInventory[index] = e.target.value;
                                return newInventory;
                              });
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            color="success"
                            onClick={() => {
                              //check if quantity is a number
                              if (isNaN(newQuantities[index]) || newQuantities[index] === "") {
                                Error_Message("Quantity must be a number!");
                                return;
                              }
                              if (newBins[index]) {
                                axios.post(`${ip}/inventory/changeBin`, {
                                  id: product.id,
                                  bin: newBins[index],
                                }).then((response) => {
                                  if (response.data) {
                                    setStatusClick(!statusClick);
                                    Success_Message("Updated!");
                                  }
                                });
                              }
                              if (newQuantities[index] !== product.quantity) {
                                axios
                                  .post(`${ip}/inventory/changeQuantity`, {
                                    id: product.id,
                                    quantity: newQuantities[index],
                                  })
                                  .then((response) => {
                                    if (response.data) {
                                      setStatusClick(!statusClick);
                                      Success_Message("Updated!");
                                    }
                                  });
                              }
                            }}
                          >
                            Update
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <Typography level="body-sm" textAlign={"center"}>
                No items have been stocked yet.
              </Typography>
            )}
          </Card>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Inventory;
