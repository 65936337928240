import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  Input,
  Link,
  CircularProgress,
  Container,
  Divider,
} from "@mui/joy";
import stripe from "../../images/stripe.svg";
const { ip } = require("../../ip.js");

function Payment(props) {
  const user = props.user;
  const navigate = useNavigate();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const location = useLocation();
  const [donation_amount, set_donation_amount] = useState(
    location?.state?.donation_amount
  );
  const [donationInput, setDonationInput] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState(
    location?.state?.organization
  );
  const [checkedItems, setCheckedItems] = useState(
    location?.state?.checkedItems
  );
  const [loading, setLoading] = useState(false);
  const isAuthenticated = props.isAuthenticated;
  const isMobile = props.isMobile;
  const [admin, setAdmin] = useState({});

  function add_fees(amount) {
    return (amount * 1.03 + 0.3).toFixed(2);
  }

  function validate_email(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
    if (organization) {
      axios.get(`${ip}/user/admin/${organization}`).then((res) => {
        if (res.data.length === 0) {
          return;
        }
        setAdmin(res.data[0]);
      });
    }
  }, [organization]);

  useEffect(() => {
    axios.get(`${ip}/config-live`).then((r) => {
      const publishableKey = r.data["publishableKey"];
      setStripePromise(loadStripe(publishableKey));
    });
  }, [donationInput]);

  useEffect(() => {
    if (donation_amount || donationInput) {
      if (donationInput && donationInput < 1) {
        return;
      }
      if (!isAuthenticated) {
        if (!email || !name || !validate_email(email)) {
          return;
        }
      }
      setLoading(true);
      setTimeout(() => {
        axios
          .post(`${ip}/create-payment-intent-live`, {
            method: "POST",
            amount: add_fees(donation_amount || donationInput),
            email: user?.email || email,
          })
          .then((result) => {
            var clientSecret = result.data["clientSecret"];
            setClientSecret(clientSecret);
            setLoading(false);
          });
      }, 3000);
    } else {
      setClientSecret("");
    }
  }, [donationInput, email, name]);

  function email_donator(
    to_email_id,
    from_email,
    from_name,
    from_organization,
    content,
    status,
    ask_id
  ) {
    axios.get(`${ip}/email/${to_email_id}`).then((response) => {
      if (response.data) {
        emailjs
          .send(
            "service_s2rydx7",
            "template_bs16y39",
            {
              to_email: response.data.email,
              from_email: from_email,
              from_name: from_name,
              from_organization: from_organization || "N/A",
              content: content,
              status: status,
              ask_id: ask_id,
              donation: donationInput,
            },
            "x7LM43rlkJsqov2r7"
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("email sent");
            } else {
              console.log("email not sent");
            }
          });
      }
    });
  }

  return (
    <>
      <ToastContainer />
      <Container maxWidth="md">
        <Card sx={{ padding: 4, boxShadow: 3 }}>
          <Stack spacing={3}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography level="h2" component="h2">
                Donate
              </Typography>
              <img
                src={stripe}
                onClick={() => window.open("https://www.stripe.com", "_blank")}
                style={{ cursor: "pointer", height: 50 }}
                alt="Stripe Logo"
              />
              <Link href="https://www.stripe.com" target="_blank"></Link>
            </Stack>
            <Typography level="h4">Payment Details</Typography>
            <Divider />
            {!isAuthenticated && (
              <>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography level="body-lg">Name:</Typography>
                  <Input
                    size="md"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    error={!name}
                    fullWidth
                  />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography level="body-lg">Email:</Typography>
                  <Input
                    size="md"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!email || !validate_email(email)}
                    fullWidth
                  />
                </Stack>
              </>
            )}
            <Stack spacing={2} alignItems="flex-start">
              {donation_amount ? (
                <>
                  <Typography level="body-lg">
                    Before Fees: ${donation_amount}
                  </Typography>
                  <Typography level="body-lg">
                    Total After Fees (3%): <b>${add_fees(donation_amount)}</b>
                  </Typography>
                </>
              ) : (
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography level="body-lg">Amount:</Typography>
                  <Input
                    type="number"
                    size="md"
                    placeholder="Enter Amount"
                    value={donationInput}
                    startDecorator="$"
                    onChange={(e) => setDonationInput(e.target.value)}
                    error={!donationInput}
                    fullWidth
                  />
                </Stack>
              )}
              {donationInput && donationInput < 1 ? (
                <Typography level="body-lg" color="error">
                  Total After Fees (3%)
                </Typography>
              ) : donationInput ? (
                <>
                  <Typography level="body-lg">
                    Total After Fees (3%): <b>${add_fees(donationInput)}</b>
                  </Typography>
                </>
              ) : null}
              {organization ? (
                <Typography level="body-lg">
                  Receiver: <b>{organization}</b>
                </Typography>
              ) : (
                <Typography level="body-lg">
                  Receiver: <b>Detroit Public Schools</b>
                </Typography>
              )}
            </Stack>
            {loading ? (
              <CircularProgress />
            ) : clientSecret && stripePromise ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  organization={organization}
                  user={user}
                  Success_Message={props.Success_Message}
                  Error_Message={props.Error_Message}
                  donationInput={donationInput}
                  donation_amount={donation_amount}
                  email_donator={email_donator}
                  content={checkedItems}
                  status={"Donation"}
                  ask_id={null}
                  admin={admin || null}
                  add_fees={add_fees}
                  email={email}
                />
              </Elements>
            ) : (
              <Typography>
                Enter information above to see payment details
              </Typography>
            )}
          </Stack>
        </Card>
      </Container>
    </>
  );
}

export default Payment;
