import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import {
    Button,
    Stack,
    Typography,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Grid,
    CircularProgress,
} from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const donationInput = props.donationInput;
    const donation_amount = props.donation_amount;
    const organization = props.organization;
    const Success_Message = props.Success_Message;
    const Error_Message = props.Error_Message;
    const email_donator = props.email_donator;
    const admin = props.admin;
    const user = props.user;
    const status = props.status;
    const content = props.content;
    const email = props.email;
    const add_fees = props.add_fees;

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!donationInput && !donation_amount) {
            Error_Message("Please enter a donation amount");
            return;
        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required",
        });

        if (error?.type === "card_error" || error?.type === "validation_error") {
            setMessage(error.message);
            return;
        }
        //Email(admin?.email, user?.email, user?.fullName, user?.organization, content, status, null, donationInput);
        navigate("/completion", { replace: true });

        setIsProcessing(false);
    };

    return (
      <Stack spacing={2} alignItems={"center"}>
        <PaymentElement id="payment-element" />
        <Button
          onClick={handleSubmit}
          disabled={isProcessing || !stripe || !elements}
          id="submit"
          size="md"
          sx={{ width: "300px" }}
        >
          {isProcessing ? <>Processing ...  {" "} <CircularProgress /></> : `Pay $${donationInput ? add_fees(donationInput) : add_fees(donation_amount)}`}
        </Button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </Stack>
    );
}
